<template>
  <div class="DashboardHeader" :class="classes">
    <header-navigation ref="navigation" v-if="showBurger" />

    <div class="d-flex align-items-center flex-grow-1">
      <usage-hint v-if="user.subscription_status !== 'lifetime'" />
      <sms-calls-usage-warn />
    </div>

    <header-user />
  </div>
</template>

<script>
import HeaderNavigation from './HeaderNavigation/Navigation.vue'
import HeaderUser from './HeaderUser/HeaderUser.vue'
import UsageHint from './HeaderBilling/UsageHint.vue'
import SmsCallsUsageWarn from '@/components/Layouts/Dashboard/DashboardHeader/HeaderBilling/SmsCallsUsageWarn.vue'

export default {
  components: {
    SmsCallsUsageWarn,
    HeaderNavigation,
    HeaderUser,
    UsageHint
  },

  methods: {
    closeSidebar () {
      if (this.$refs.navigation) {
        this.$refs.navigation.closeSidebar()
      }
    }
  },

  computed: {
    showBurger () {
      return this.$mq !== 'desktop'
    },

    classes () {
      let classes = []
      classes.push(`sidebar-` + this.sidebar)
      classes.push(this.$mq)

      return classes
    },

    ...mapState('userPreferences', [
      'sidebar'
    ]),

    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .DashboardHeader {
    position: fixed;
    top: 0;
    left: 0;

    z-index: 10;

    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 60px;

    padding: 0 20px;

    background-color: #fff;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, .03);

    &.desktop.sidebar-tablet {
      left: 74px;
      width: calc(100% - 74px);
    }

    &.desktop.sidebar-desktop {
      left: 250px;
      width: calc(100% - 250px);
    }
  }
</style>
