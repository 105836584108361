<template>
  <div v-if="user.sms_calls_left != null && user.sms_calls_left <= 10" style="display: flex; margin-left: 40px;">
    <p class="text">
      👉 &nbsp;Your SMS/Call balance is running low. <a style="color: #6551E0" href="#" @click.prevent="redirectToAddons">Add more services here.</a>
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
  },

  methods: {
    redirectToAddons () {
      window.location.href = '/account/addons'
    }
  },

  computed: {
    ...mapState('authentication', ['user'])
  }
}
</script>
